import Types from "./types";
import {
  fetchStudents,
  fetchSpecialNeedStudents,
  fetchUnReStudents,
  fetchAcceptedStudents,
  fetchPaidStudents,
  fetchStudent,
  fetchUnReStudent,
  fetchAcceptedApplicant,
  editPrios,
  review,
  fetchDates,
  setNewDate,
  setContact,
  editScores,
  delDate,
  editCapacity,
  email,
  saveNotes,
  addUser,
  fetchEvalStudent,
  evaluate,
  updateApplicant,
  fetchAllEquations,
  deleteEquation,
  editEquation,
  changeEquation,
  newEquation,
  filesUpload,
  filesReUpload,
  UploadOtherFiles,
  setExempt,
  getNotes,
  filesUpdation,
  documentSelection,
  documentsGet,
  getScoresForRegistration,
  setScoresForRegistration,
  changeDocument,
} from "../services/adminServices";
import types from "./types";
// import { reset } from "redux-form";
import { toast } from "react-toastify";
import axios from "axios";
import { API } from "../../../common/constants";

const handleError = (err, type, dispatch, showToast = true, warn, redirect) => {
  let lang = localStorage.getItem("i18nextLng");
  let sessionErr =
    lang === "en"
      ? "Session Time Out. Please Try To Login Again"
      : "انتهت صلاحية الجلسة. من فضلك اعد تسجيل الدخول";
  if (err.response) {
    if (err.response.status >= 500) {
      showToast &&
        toast[warn ? "warn" : "error"](
          lang === "en" ? "Connection Error" : "خطأ في الإتصال"
        );
      dispatch({ type, payload: ["Connection Error", "خطأ في الإتصال"] });
    } else if (err.response.status === 403) {
      localStorage.removeItem("user");
      redirect ? redirect() : window.location.reload();
      toast.warn(sessionErr);
    } else if (err.response.status >= 400) {
      let errResData = err.response.data;
      showToast &&
        toast[warn ? "warn" : "error"](
          typeof errResData === "object"
            ? Object.values(errResData).length >= 2
              ? Object.values(errResData)[lang === "ar" ? 1 : 0].toString()
              : Object.values(errResData)[0].toString()
            : [errResData]
        );
      dispatch({
        type,
        payload:
          typeof errResData === "object"
            ? Object.values(errResData)
            : [errResData],
      });
    }
  } else {
    showToast &&
      toast[warn ? "warn" : "error"](
        lang === "en" ? "Connection Error" : "خطأ في الإتصال"
      );
    dispatch({ type, payload: ["Connection Error", "خطأ في الإتصال"] });
  }
};

// *====> STUDENTS <====*
// Indicate Loading Progress
export const fetchingStudents = () => ({ type: Types.GET_STUDENTS_PROGRESS });

export const fetchingPaidStudents = () => ({
  type: Types.GET_PAID_STUDENTS_PROGRESS,
});

// Clean Component after unmount
export const cleanError = () => ({ type: Types.CLEAN_ERROR });

export const clean = () => ({ type: Types.CLEAN });

// Send Email
export const sendEmail = (body, callback) => async (dispatch) => {
  try {
    const { data } = await email(body);

    dispatch({
      type: Types.CHANGE_APP_STATE_SUCCESS,
      payload: data,
    });

    callback();
  } catch (err) {
    let toast = true;
    handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
  }
};
// change applicant info
export const changeAppInfo = (body, success, callback) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const { data } = await updateApplicant(body);
    // dispatch({
    //   type: Types.CHANGE_APP_INFO_SUCCESS,
    //   payload: data,
    // });
    callback && callback();
    success && success();
  } catch (err) {
    let toast = true;
    handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
  }
};

export const APPLICANT_UPDATE_SUCCESS = "applicant_update_success";
export const APPLICANT_MODAL_MOUNT = "applicant_modal_mount";
export const resetAppForm = () => ({ type: APPLICANT_UPDATE_SUCCESS });
export const fillAppForm = (data) => ({
  type: APPLICANT_MODAL_MOUNT,
  payload: data,
});

// change change contact
export const changeContact = (contact, redirect) => async (dispatch) => {
  try {
    const { data } = await setContact(contact);

    dispatch({
      type: Types.CHANGE_APP_STATE_SUCCESS,
      payload: data,
    });

    redirect();
  } catch (err) {
    let toast = true;
    handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
  }
};

// change exepmt
export const changeExepmt = (contact, callback) => async (dispatch) => {
  try {
    const { data } = await setExempt(contact);
    dispatch({
      type: Types.CHANGE_APP_STATE_SUCCESS,
      payload: data,
    });
    callback && callback(data);
  } catch (err) {
    let toast = true;
    handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
  }
};

// edit applicant scores
export const changeScores = (scores, redirect) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const { data } = await editScores(scores);

    dispatch({
      type: Types.CHANGE_APP_SCORES_SUCCESS,
      payload: scores,
    });

    redirect && redirect();
  } catch (err) {
    let toast = true;
    handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
  }
};

export const notes = (notes, redirect) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const { data } = await saveNotes(notes);

    dispatch({
      type: Types.CHANGE_APP_NOTES_SUCCESS,
      payload: notes.notes,
    });
    redirect && redirect();
  } catch (err) {
    let toast = true;
    handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
  }
};

export const getNotesByID = (id, type) => async (dispatch) => {
  console.log("the type", type)
  try {
    const { data } = await getNotes(id, type);
    dispatch({
      type: Types.GET_NOTES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log("te err");
  }
};

// edit applicant scores
export const updatePrios =
  (prios, redirect, deleteform) => async (dispatch) => {
    try {
      const { data } = await editPrios(prios);
      dispatch({
        type: Types.CHANGE_APP_STATE_SUCCESS,
        payload: data,
      });
      redirect && redirect();
    } catch (err) {
      let toast = true;
      handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
    }
  };

// Fetch Single Student Data
export const getStudentReq = () => ({ type: Types.GET_STUDENT_PROGRESS });

export const getStudent = (id, callback) => async (dispatch) => {
  try {
    const { data } = await fetchStudent(id);
    dispatch({
      type: Types.GET_STUDENT_SUCCESS,
      payload: data,
    });

    callback && callback(data);
  } catch (err) {
    handleError(err, Types.GET_STUDENT_ERROR, dispatch);
  }
};

// Fetch Single Student Data
export const getEvalStudent = (id) => async (dispatch) => {
  try {
    const { data } = await fetchEvalStudent(id);

    dispatch({
      type: Types.GET_STUDENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    handleError(err, Types.GET_STUDENT_ERROR, dispatch);
  }
};

export const getUnReStudent = (id, callback) => async (dispatch) => {
  try {
    const { data } = await fetchUnReStudent(id);
    dispatch({
      type: Types.GET_STUDENT_SUCCESS,
      payload: data,
    });
    callback && callback(data);
  } catch (err) {
    handleError(err, Types.GET_STUDENT_ERROR, dispatch);
  }
};

export const getAcceptedApplicant = (id) => async (dispatch) => {
  try {
    const { data } = await fetchAcceptedApplicant(id);

    dispatch({
      type: Types.GET_STUDENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    handleError(err, Types.GET_STUDENT_ERROR, dispatch);
  }
};

// Fetch All Students based on thier status (all/UR/IA/CA/RJ) with pagination
export const getStudents = (filterParams) => async (dispatch) => {
  try {
    const { data } = await fetchStudents(filterParams);

    dispatch({
      type: Types.GET_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    handleError(err, Types.GET_STUDENTS_ERROR, dispatch);
  }
};

export const getEvalStudents =
  (page = 0, status = "all", query = "", major, smster) =>
  async (dispatch) => {
    try {
      const { data } = await fetchStudents(page, "eval", query, major, smster);

      dispatch({
        type: Types.GET_STUDENTS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      handleError(err, Types.GET_STUDENTS_ERROR, dispatch);
    }
  };

export const getUnReStudents = (filterParams) => async (dispatch) => {
  try {
    const { data } = await fetchUnReStudents(filterParams);

    dispatch({
      type: Types.GET_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    handleError(err, Types.GET_STUDENTS_ERROR, dispatch);
  }
};

export const getAcceptedStudents = (filterParams) => async (dispatch) => {
  try {
    const { data } = await fetchAcceptedStudents(filterParams);

    dispatch({
      type: Types.GET_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    handleError(err, Types.GET_STUDENTS_ERROR, dispatch);
  }
};

export const getPaidStudents = (filterParams) => async (dispatch) => {
  try {
    const { data } = await fetchPaidStudents(filterParams);

    dispatch({
      type: Types.GET_PAID_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    handleError(err, Types.GET_PAID_STUDENTS_ERROR, dispatch);
  }
};

export const getSpecialNeedStudents = (filterParams) => async (dispatch) => {
  try {
    const { data } = await fetchSpecialNeedStudents(filterParams);

    dispatch({
      type: Types.GET_SPECIAL_NEED_STUDENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    handleError(err, Types.GET_SPECIAL_NEED_STUDENT_ERROR, dispatch);
  }
};

export const fetchingEquations = () => ({ type: Types.GET_EQUATIONS_PROGRESS });

export const getEquations = (callback) => async (dispatch) => {
  try {
    const { data } = await fetchAllEquations();
    dispatch({
      type: Types.GET_EQUATIONS_SUCCESS,
      payload: data,
    });
    callback && callback(data);
  } catch (err) {
    if (err.response.data.courses.length > 0) {
      dispatch({
        type: Types.GET_EQUATIONS_SUCCESS,
        payload: err.response.data,
      });
    }
    handleError(err, Types.GET_EQUATIONS_ERROR, dispatch);
  }
};

export const equationRequest = () => ({ type: Types.EQUATION_PROGRESS });

export const createEquation = (equation, callBack) => async (dispatch) => {
  try {
    const { data } = await newEquation(equation);
    // dispatch({
    //   type: Types.NEW_EQUATION_SUCCESS,
    //   payload: data.new_equivilant,
    // });
    callBack && callBack(data);
  } catch (err) {
    handleError(err, Types.EQUATION_DONE, dispatch, true);
  }
};

export const removeEquation = (equation, callback) => async (dispatch) => {
  try {
    const { data } = await deleteEquation(equation);
    // dispatch({
    //   type: Types.DEL_EQUATION_SUCCESS,
    //   payload: data.new_equivilant,
    // });
    callback && callback(data);
  } catch (err) {
    handleError(err, Types.EQUATION_DONE, dispatch, true);
  }
};

export const modifyEquation = (equation, callBack) => async (dispatch) => {
  try {
    const { data } = await editEquation(equation);
    dispatch({
      type: Types.EDIT_EQUATION_SUCCESS,
      payload: data.new_equivilant,
    });
    callBack && callBack(data);
  } catch (err) {
    handleError(err, Types.EQUATION_DONE, dispatch, true);
  }
};
export const submitList = () => ({ type: Types.EDIT_DOCUMENT_PROGRESS });
export const modifyUploads = (dataReq, callBack) => async (dispatch) => {
  try {
  
    const { data } = await changeDocument(dataReq);

    dispatch({
      type: Types.EDIT_DOCUMENT_SUCCESS,
      payload: data,
    });
    await dispatch(submitList());
    dispatch(listDocuments(dataReq.id));
    // callBack && callBack();
  } catch (err) {
    handleError(err, Types.EDIT_DOCUMENT_ERROR, dispatch, true);
  }
};
export const changeEquationState = (state, callBack) => async (dispatch) => {
  try {
    const { data } = await changeEquation(state);
    dispatch({ type: Types.EQUATION_DONE });
    callBack && callBack(data);
  } catch (err) {
    handleError(err, Types.EQUATION_DONE, dispatch, true);
  }
};

export const setReviewRequest = () => {
  return {
    type: Types.CHANGE_APP_STATE_REQUEST,
  };
};

// change the student initail state
export const setReview = (documents, redirect) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const data = await review(documents);
    dispatch({
      type: Types.CHANGE_APP_STATE_SUCCESS,
      payload: "Review Submitted",
    });

    redirect();
  } catch (err) {
    let toast = true;
    handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
  }
};

// change the student initail state
export const setEvaluation = (reqData, success, update) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const data = await evaluate(reqData);
    dispatch({
      type: Types.CHANGE_APP_STATE_SUCCESS,
      payload: "Review Submitted",
    });
    success();
    update();
  } catch (err) {
    let toast = true;
    handleError(err, Types.CHANGE_APP_STATE_ERROR, dispatch, toast);
  }
};

// *====> DATES <====*

export const settingDate = () => {
  return {
    type: types.SET_DATES_PROGRESS,
  };
};

export const fetchingInterviewDates = () => ({
  type: Types.GET_INTRDATES_PROGRESS,
  payload: "",
});

export const fetchingEngDates = () => ({
  type: Types.GET_ENGDATES_PROGRESS,
  payload: "",
});

// Get All Dates
export const getInterviewDates = (gender, degree) => async (dispatch) => {
  try {
    const testType = "interview";
    if (gender) {
      const { data } = await fetchDates(testType, gender,degree);

      dispatch({ type: Types.GET_INTRDATES_SUCCESS, payload: data });
    } else {
      const { data } = await fetchDates(testType);

      dispatch({ type: Types.GET_INTRDATES_SUCCESS, payload: data });
    }
  } catch (err) {
    let toast = true;
    let warn = true;
    handleError(err, Types.GET_INTRDATES_ERROR, dispatch, toast, warn);
  }
};

export const getEngDates = (gender,id, degree) => async (dispatch) => {
  try {
    const testType = "english";
    if (gender) {
      const { data } = await fetchDates(testType, gender,id, degree);
      dispatch({ type: Types.GET_ENGDATES_SUCCESS, payload: data });
    } else {
      console.log("the value is here")
      const { data } = await fetchDates(testType);
      dispatch({ type: Types.GET_ENGDATES_SUCCESS, payload: data });
    }
  } catch (err) {
    let toast = true;
    let warn = true;
    handleError(err, Types.GET_ENGDATES_ERROR, dispatch, toast, warn);
  }
};

// Add Date
export const addEngDate = (dates, callback) => async (dispatch) => {
  try {
    const { data } = await setNewDate(dates, 1);

    dispatch({ type: Types.GET_ENGDATES_SUCCESS, payload: data });
    callback();
  } catch (err) {
    let toast = true;
    handleError(err, Types.GET_ENGDATES_ERROR, dispatch, toast);
  }
};

export const addIntrDate = (dates, callback) => async (dispatch) => {
  try {
    const { data } = await setNewDate(dates, 2);

    dispatch({ type: Types.GET_INTRDATES_SUCCESS, payload: data });
    callback();
  } catch (err) {
    let toast = true;
    handleError(err, Types.GET_INTRDATES_ERROR, dispatch, toast);
  }
};

// Remove Date
export const removeEngDate = (date, callback) => async (dispatch) => {
  try {
    const test_type = "1";
    const { data } = await delDate(date, test_type);
    await dispatch(getEngDates());
    // dispatch({ type: Types.GET_ENGDATES_SUCCESS, payload: data });
    callback();
  } catch (err) {
    handleError(err, Types.GET_ENGDATES_ERROR, dispatch);
  }
};

export const removeIntrDate = (date, callback) => async (dispatch) => {
  try {
    const test_type = "2";
    const { data } = await delDate(date, test_type);
    await dispatch(getInterviewDates())
    // dispatch({ type: Types.GET_INTRDATES_SUCCESS, payload: data });
    callback();
  } catch (err) {
    handleError(err, Types.GET_INTRDATES_ERROR, dispatch);
  }
};

// Edit Capacity
export const editCapacityEngDate = (capacity, callback) => async (dispatch) => {
  try {
    const test_type = "1";
    const { data } = await editCapacity(capacity, test_type);

    dispatch({ type: Types.GET_ENGDATES_SUCCESS, payload: data });
    callback && callback();
  } catch (err) {
    handleError(err, Types.GET_ENGDATES_ERROR, dispatch);
  }
};

export const editCapacityIntrDate =
  (capacity, callback) => async (dispatch) => {
    try {
      const test_type = "2";
      const { data } = await editCapacity(capacity, test_type);

      dispatch({ type: Types.GET_INTRDATES_SUCCESS, payload: data });
      callback && callback();
    } catch (err) {
      handleError(err, Types.GET_INTRDATES_ERROR, dispatch);
    }
  };

export const creatingUser = () => ({ type: Types.SET_NEW_USER_REQUEST });

export const createUser = (values, callback) => async (dispatch) => {
  try {
    const { data } = await addUser(values);

    dispatch({ type: Types.SET_NEW_USER_SUCCESS, payload: data });
    callback && callback();
  } catch (err) {
    let toast = true;
    handleError(err, Types.SET_NEW_USER_ERROR, dispatch, toast);
  }
};

// score for registration
export const SetScoresForRegistration = (values, callback) => async (dispatch) => {
  try {
    const { data } = await setScoresForRegistration(values);

    dispatch({ type: Types.SET_SCORE_FOR_REGISTRATION_SUCESS, payload: data });
    callback && callback();
  } catch (err) {
    let toast = true;
    handleError(err, Types.SET_SCORE_FOR_REGISTRATION_ERROR, dispatch, toast);
  }
};
export const settingScoresforRegistration = (values, callback) => async(dispatch) => {
  dispatch({ type: Types.SET_SCORE_FOR_REGISTRATION_PROGRESS, payload: {} });
  callback && callback();
}

export const GetScoresForRegistration = (values, callback) => async (dispatch) => {
  try {
    const { data } = await getScoresForRegistration(values);

    dispatch({ type: Types.GET_SCORE_FOR_REGISTRATION_SUCESS, payload: data });
    callback && callback();
  } catch (err) {
    let toast = true;
    handleError(err, Types.GET_SCORE_FOR_REGISTRATION_ERROR, dispatch, toast);
  }
};
export const gettingScoresforRegistration = () => async(dispatch) => {
  dispatch({ type: Types.GET_SCORE_FOR_REGISTRATION_PROGRESS, payload: {} });
  // callback && callback();
}

// *========> Documents <========*
// actions indicate loading
export const uploading = () => ({ type: Types.UPLOAD_PROGRESS, payload: true });
// upload
export const upload = (files, redirect) => async (dispatch) => {
  try {
    const { data } = await filesUpload(files, redirect);
    dispatch({
      type: Types.UPLOAD_SUCCESS,
      payload: data,
    });

    redirect && redirect();
  } catch (err) {
    let toast = true;
    handleError(err, Types.UPLOAD_ERROR, dispatch, toast);
  }
};
// re upload
export const reUpload = (files, redirect) => async (dispatch) => {
  try {
    const { data } = await filesUpdation(files, redirect);
    dispatch({
      type: Types.UPLOAD_SUCCESS,
      payload: data,
    });

    redirect && redirect();
  } catch (err) {
    // let toast = true;
    // handleError(err, Types.UPLOAD_ERROR, dispatch, toast);
  }
};
// select documents
export const selectDocumentsReq = () => ({ type: Types.ADD_DOCUMENT_PROGRESS });
export const selectDocuments = (files, redirect) => async (dispatch) => {
  try {
    console.log("the value in actions");
    const { data } = await documentSelection(files, redirect);
    console.log(data);
    console.log(files);
    await dispatch({
      type: Types.ADD_DOCUMENT_SUCCESS,
      payload: data,
    });
    await dispatch(listDocuments(files.id));
    redirect && redirect();
  } catch (err) {
    // let toast = true;
    // handleError(err, Types.UPLOAD_ERROR, dispatch, toast);
  }
};

export const listDocuments = (files, redirect) => async (dispatch) => {
  try {
    const { data } = await documentsGet(files);
    dispatch({
      type: Types.GET_DOCUMENT_SUCCESS,
      payload: data,
    });

    redirect && redirect();
  } catch (err) {
    // let toast = true;
    // handleError(err, Types.UPLOAD_ERROR, dispatch, toast);
  }
};
// clean after component unmount
export const cleanErrorUpload = () => {
  return {
    type: Types.UPLOAD_ERROR_CLEAN,
    payload: [],
  };
};

export const uploadingOtherFiles = () => ({
  type: Types.UPLOAD_OTHER_FILES_PROGRESS,
  payload: true,
});
// upload other files
export const uploadOtherFiles =
  (
    files,
    otherExist,
    transcriptExist,
    editTranscript,
    editOthers,
    editEnglishCertif,
    englishExist,
    satExist,
    redirect
  ) =>
  async (dispatch) => {
    try {
      const { data } = await UploadOtherFiles(
        files,
        otherExist,
        transcriptExist,
        editTranscript,
        editOthers,
        editEnglishCertif,
        englishExist,
        satExist
      );
      dispatch({
        type: Types.UPLOAD_OTHER_FILES_SUCCESS,
        payload: data,
      });
      redirect && redirect();
      // window.location.reload();
    } catch (err) {
      let toast = true;
      handleError(err, Types.UPLOAD_OTHER_FILES_ERROR, dispatch, toast);
    }
  };

// clean after component unmount
export const cleanErrorUploadOthers = () => {
  return {
    type: Types.UPLOAD_OTHER_FILES_ERROR_CLEAN,
    payload: [],
  };
};
