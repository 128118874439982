import Types from "./types";

const INITIAL_STATE = {
  students: false,
  loaded: false,
  error: "",
  scoresforRegistration:[],
  getScoresLoading: false,
  setScoresLoading:false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_STUDENTS_SUCCESS:
      return { loaded: true, students: action.payload, error: "" };
    case Types.GET_STUDENTS_ERROR:
      return { ...state, error: action.payload, loaded: true };
    case Types.GET_STUDENTS_PROGRESS:
      return { ...state, loaded: false };
    case Types.GET_SCORE_FOR_REGISTRATION_SUCESS:
      return {...state, scoresforRegistration: action.payload, getScoresLoading: false}
    case Types.GET_SCORE_FOR_REGISTRATION_PROGRESS:
      return {...state, getScoresLoading: true}
    case Types.SET_SCORE_FOR_REGISTRATION_PROGRESS:
      return {...state, setScoresLoading: true}
      case Types.SET_SCORE_FOR_REGISTRATION_SUCESS:
        return {...state, setScoresLoading: false}
    case Types.CLEAN:
      return { loaded: false, students: false, error: "" };
    default:
      return state;
  }
};
